const Header = () =>
  <div className={'about-header'}>
    <h1>About</h1>
  </div>

const Section = () =>
  <div>
    <h3></h3>
    <div>

    </div>
  </div>;

const About = () =>
  <div>
    <Header/>
    <div>

    </div>
  </div>;

export default About;